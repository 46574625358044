import axios, { AxiosError } from "axios";
import { ICancelDTO } from "../Models/DTOs/ICancelDTO";
import IPayInvoiceDTO from "../Models/DTOs/IPayInvoiceDTO";
import IPaymentIntentResultDTO from "../Models/DTOs/IPaymentIntentResultDTO";
import IPaymentMethodInformation from "../Models/DTOs/IPaymentMethodInformation";
import IUnpaidDTO from "../Models/DTOs/IUnpaidDTO";
import { PlanType } from "../Models/Enums/PlanType";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.stripe;

export async function AddPaymentMethod(aspNetUserId: string, paymentMethodId: string, abortController: AbortController) {
    const responce = await axios.post(BACKEND_URL + "AddPaymentMethod/" + aspNetUserId + "/" + paymentMethodId,
        {
            signal: abortController.signal,
        }
    ).then((responce) => {
        //console.log(responce);
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("AddPaymentMethod error: ");
        return error;
    });

    return responce;
};

export async function CreateSubscription(email: string, planId: string, aspNetUserId: string, type: PlanType, tokenOrPaymentMethod: string, affiliateId?: number) {
    const responce = await axios.post(BACKEND_URL + "CreateSubscription",
        {
            email: email,
            plan: planId,
            aspNetUserId: aspNetUserId,
            planType: type,
            tokenOrPm: tokenOrPaymentMethod,
            affiliateId: affiliateId
        }).then((responce) => {
            //console.log(responce);
            return responce.data as boolean;
        }).catch((error: AxiosError) => {
            console.log("CreateSubscription error");
            return error;
        });

    return responce;
}

export async function UpgradeSubscription(planId: string, aspNetUserId: string, type: PlanType, abortController: AbortController) {
    const responce = await axios.post(BACKEND_URL + "UpgradeSubscription/" + aspNetUserId + "/" + planId + "/" + type, null, {
        signal: abortController.signal
    }).then((responce) => {
        //console.log(responce);
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("UpgradeSubscription error ");
        return error;
    });

    return responce;
}

export async function CancelSubscription(data: ICancelDTO, access_token: string, abortController: AbortController) {

    data.Message = data.Message.split("&").join("and").trim();

    const responce = await axios.post(BACKEND_URL + "CancelSubscription", data, {
        signal: abortController.signal,
        headers: {
            Authorization: "Bearer " + access_token
        }
    }).then((responce) => {
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("CancelSubscription ");
        return error;
    });

    return responce;
}

export async function AddExtraDays(aspNetUserId: string, abortController: AbortController) {

    const responce = await axios.post(BACKEND_URL + "AddExtraDays/" + aspNetUserId, null, {
        signal: abortController.signal,
    }).then((responce) => {
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("AddExtraDays ");
        return error;
    });

    return responce;
}

export async function GetRenewDetails(aspNetUserId: string, paymentMethodId: string, abortController: AbortController) {
    const responce = await axios.get(BACKEND_URL + "GetRenewDetails/" + aspNetUserId + "/" + paymentMethodId, { signal: abortController.signal }).then((result) => {
        return result.data as IUnpaidDTO;
    }).catch((error: AxiosError) => {
        console.log("GetRenewDetails error: ");
        return error;
    });

    return responce;
}

export async function PayInvoice(payInvoiceDTO: IPayInvoiceDTO, abortController: AbortController) {
    const responce = await axios.post(BACKEND_URL +
        "PayInvoice",
        payInvoiceDTO,
        { signal: abortController.signal }).then((result) => {
            return result.data as IPaymentIntentResultDTO;
        }).catch((error: AxiosError) => {
            console.log("PayInvoice error: ");
            return error;
        });

    return responce;
}

export async function CheckPaymentIntentStatus(userId: string, abortController: AbortController) {
    const response = await axios.get(BACKEND_URL + "CheckPaymentIntentStatus/" + userId,
        { signal: abortController.signal }).then((result) => {
            //console.log(result.data);
            if (result.data === true) {
                return true;
            }
            return false;
        }).catch((error: AxiosError) => {
            console.log('CheckPaymentIntentStatus error')
            return error;
        });

    return response;
}

export async function GetPaymentMethodInformation(userId: string, paymentMethodId: string, abortController: AbortController) {
    const response = await axios.get(BACKEND_URL + "GetPaymentMethodInformation/" + userId + "/" + paymentMethodId,
        { signal: abortController.signal }).then((result) => {
            return result.data as IPaymentMethodInformation;
        }).catch((error: AxiosError) => {
            return error;
        });

    return response;
}
